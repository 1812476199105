import { useState, useMemo, useEffect } from 'react'
import {
    TextField,
    InputAdornment,
    IconButton,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Typography,
    ListSubheader,
    FormControl,
    FormGroup,
    FormHelperText,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@mui/material'
import tw, { styled } from 'twin.macro'
import { useForm, Controller } from 'react-hook-form'
import { useMutation, useQuery } from '@tanstack/react-query'
import { always, cond, equals, filter, isEmpty, split, T, trim } from 'ramda'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import dayjs from 'dayjs'
import { useTranslation, Trans } from 'react-i18next'

import { loginReg } from 'service/authService'
import { crm } from 'service/crmService'
import { useValidationI18n } from 'utils/validationI18n'
import { yearList, languagezhHKList, languageEnUSList, languagezhCNList } from 'utils/staticData'
import { useLangStore, useIASMStore } from 'store/authAtom'
import { useDisclosure } from 'utils/useDisclosure'
import { getNameByCode, getNameByIsoCode, getContentType } from 'utils/filter'
import { user } from 'service/userService'
import { information } from 'service/infoService'
import { useUpdateErrorMessages } from 'utils/filter'

import TipMotal from 'components/TipMotal'
import ViewOff from 'resources/svg/view_off.svg'
import View from 'resources/svg/view.svg'
import Iamsmart from 'resources/svg/iamsmart_tag.svg'
import { encrypt_signUp } from 'utils/crypto'

import 'style/ckeditor5.css'

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
    borderTop: '1px solid ' + theme.palette.divider, // 上边的分割线
}))
const isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

const IasmIcon = tw.img``
const Form = styled.form`
    ${tw`space-y-4 w-full relative`}
    & .MuiFormLabel-root {
        display: inline-table;
    }
`
const DialogTitleWrap = styled(DialogTitle)`
    ${tw`m-0 p-4`}
`

const IconButtonWrap = styled(IconButton)`
    ${tw`absolute right-2 top-3 text-[#231448]`}
`

const SpanWrap = tw.span`text-primaryRed-spanKtRed underline`

const ImgIcon = tw.img``
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
}

const CheckboxWrap = styled(Checkbox)`
    padding: 0 8px 8px;

    & .MuiSvgIcon-root {
        width: 25px;
        height: 25px;
    }
`
const ListSubheaderWrap = styled(ListSubheader)`
    ${tw`px-5`}
`
const MenuItemWrap = styled(MenuItem)`
    ${tw`px-8`}
`
export default function SignUpCom({ submitStatus }) {
    const { enqueueSnackbar } = useSnackbar()
    const { state: iasmInfo } = useLocation()
    const navigate = useNavigate()
    const lang = useLangStore((state) => state.lang)
    const tokenInfo = useIASMStore((state) => state.tokenInfo)
    const { t } = useTranslation()
    const { validationSchema } = useValidationI18n()

    const { isOpen, onClose, onOpen } = useDisclosure()

    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [focus, setFocus] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(1)

    const handleFocus = () => {
        setFocus(true)
    }

    const handleBlur = () => {
        setFocus(false)
    }

    const { data: countryCodeList } = useQuery({
        queryKey: ['getCountryCodeList', lang],
        queryFn: () => user.getCountryCodeList({ lang }),
        placeholderData: {
            codeList: [
                {
                    code: 852,
                },
            ],
            countryCodeList: [{ name: '', isoCode: '', area: [] }],
        },
        enabled: !!lang,
    })

    const { data: getTitle } = useQuery({
        queryKey: ['getTitle', lang],
        queryFn: () => loginReg.getTitle({ lang }),
        placeholderData: [],
        enabled: !!lang,
    })

    const { data: infoData } = useQuery({
        queryKey: ['getAdditionalInformation', lang],
        queryFn: () => information.getAdditionalInformation({ lang, type: [2, 5, 6, 7] }),
        placeholderData: [],
        enabled: !!lang,
    })

    const {
        register,
        handleSubmit,
        control,
        watch,
        getValues,
        setValue,
        formState: { errors },
        trigger,
    } = useForm({
        resolver: yupResolver(yup.object(validationSchema)),
        defaultValues: {
            languagePreference: localStorage.getItem('lang') ?? 'en_US',
            title: '',
            countryCode: '852',
            residentialArea: '',
            province: '',
            district: '',
            dayOfBirth: iasmInfo?.birthDate ? dayjs(iasmInfo?.birthDate)?.format('DD') : '',
            openIdUUID: iasmInfo?.openID ?? '',
            // enewIndicator: true, //eNew
            // eNewIndicator: false, ///Agreeing

            lastname: split(',', iasmInfo?.enName?.UnstructuredName ?? '')?.[0] ?? '',
            firstname: split(',', iasmInfo?.enName?.UnstructuredName ?? '')?.[1] ?? '',
            chineseName: iasmInfo?.chName?.ChineseName ?? '',
            monthOfBirth: iasmInfo?.birthDate ? dayjs(iasmInfo?.birthDate)?.format('MM') : '',
            yearOfBirth: iasmInfo?.birthDate ? dayjs(iasmInfo?.birthDate)?.format('YYYY') : '',
            declareIndicator: false,
            declareIndicatorAll: false,
            declareIndicatorSub1: false,
            declareIndicatorSub2: false,
            declareIndicatorSub3: false,
            declareIndicatorSub4: false,
        },
    })

    useUpdateErrorMessages(errors, trigger)

    const residentialAreaWatch = watch('residentialArea')
    const yearOfBirthWatch = watch('yearOfBirth')
    const monthOfBirthWatch = watch('monthOfBirth')
    const declareIndicatorWatch = watch('declareIndicator') // 爷级
    const subValuesWatch = [
        watch('declareIndicatorSub1'),
        watch('declareIndicatorSub2'),
        watch('declareIndicatorSub3'),
        watch('declareIndicatorSub4'),
    ]
    const handleDeclareChange = (e) => {
        const checked = e.target.checked
        setValue('declareIndicator', checked)

        if (!checked) {
            // 爷级取消选中，所有子级自动取消
            setValue('declareIndicatorAll', false)
            setValue('declareIndicatorSub1', false)
            setValue('declareIndicatorSub2', false)
            setValue('declareIndicatorSub3', false)
            setValue('declareIndicatorSub4', false)
        }
        if (submitStatus) {
            trigger() // 触发验证
        }
    }

    // 父级变化处理逻辑
    const handleParentChange = (e) => {
        const checked = e.target.checked
        setValue('declareIndicatorAll', checked)

        if (!checked) {
            // 父级取消选中，所有孙级自动取消
            setValue('declareIndicatorSub1', false)
            setValue('declareIndicatorSub2', false)
            setValue('declareIndicatorSub3', false)
            setValue('declareIndicatorSub4', false)
        } else {
            setValue('declareIndicatorSub1', true)
            setValue('declareIndicatorSub2', true)
            setValue('declareIndicatorSub3', true)
            setValue('declareIndicatorSub4', true)
        }
        if (submitStatus) {
            trigger() // 触发验证
        }
    }

    const handleChildChange = (index, e) => {
        const checked = e.target.checked

        // 更新子级状态并验证
        setValue(`declareIndicatorSub${index + 1}`, checked)

        // 如果所有孙级都选中，则父级也自动选中
        const allChecked = subValuesWatch.map((val, i) => (i === index ? checked : val)) // 更新最新状态
        const allSelected = allChecked.every((v) => v)
        setValue('declareIndicatorAll', allSelected)

        if (submitStatus) {
            trigger() // 触发验证
        }
    }
    
    const handleGetInfo = (i) => {
        setCurrentIndex(i)
        onOpen()
    }

    const children = (
        <div className='flex flex-col ml-3 gap-y-3'>
            {[1, 2, 3, 4].map((num) => (
                <FormControlLabel
                    className='justify-start'
                    key={num}
                    control={
                        <Controller
                            name={`declareIndicatorSub${num}`}
                            control={control}
                            render={({ field }) => (
                                <Checkbox
                                    {...field}
                                    className='pt-0'
                                    //   disabled={!declareIndicatorAll} // 父级未选中禁用
                                    checked={field.value}
                                    onChange={(e) => handleChildChange(num - 1, e)}
                                />
                            )}
                        />
                    }
                    label={<Typography  variant='checkboxesText'>
                        <Trans
                            i18nKey={`SignUpPage.DeclareItem${num}`}
                            components={[
                                <SpanWrap onClick={() => handleGetInfo(7)} />,
                            ]}
                        />
                    </Typography>}
                />
            ))}
        </div>
    )

    const addUserFailMutation = useMutation({
        mutationFn: loginReg.addUserFail,
        onSuccess: () => {
            const formData = getValues()

            navigate('/login/verifyEmail', {
                state: {
                    payload: {
                        ...formData,
                    },
                    backRoute: '/login/signUp',
                },
            })
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    const checkProfileExistMutation = useMutation({
        mutationFn: crm.checkProfileExist,
        onSuccess: () => {
            const {
                declareIndicator,
                declareIndicatorAll,
                declareIndicatorSub1,
                declareIndicatorSub2,
                declareIndicatorSub3,
                declareIndicatorSub4,
                ...formData
            } = getValues()
            addUserFailMutation.mutate({
                activityInterest: [],
                province: '',
                district: '',
                ...formData,
                emailId: encrypt_signUp(formData.emailId),
                mobileNo: encrypt_signUp(formData.mobileNo),
                password: encrypt_signUp(formData.password),
                confirmPassword: undefined,
                plateType: 'web',
                piplConsent:declareIndicator,
                piplConsentDate:dayjs().format()
            })
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    const provinceList = useMemo(() => {
        if (residentialAreaWatch === 'CHN') {
            return (
                filter((item) => item.isoCode === residentialAreaWatch, countryCodeList.countryCodeList)?.[0]?.[
                    'area'
                ] || []
            )
        } else {
            return false
        }
    }, [residentialAreaWatch, countryCodeList])

    const districtList = useMemo(() => {
        if (residentialAreaWatch === 'HKG') {
            return (
                filter((item) => item.isoCode === residentialAreaWatch, countryCodeList.countryCodeList)?.[0]?.[
                    'area'
                ] || []
            )
        } else {
            return false
        }
    }, [residentialAreaWatch, countryCodeList])

    const languageList = useMemo(() => {
        return cond([
            [equals('en_US'), always(languageEnUSList)],
            [equals('zh_CN'), always(languagezhCNList)],
            [equals('zh_HK'), always(languagezhHKList)],
            [T, always(languageEnUSList)],
        ])(lang)
    }, [lang])

    const popTitle = useMemo(() => {
        return cond([
            [equals(2), always(t('SignUpPage.PrivacyPolicy'))],
            [equals(6), always(t('SignUpPage.Statement'))],
            [equals(5), always(t('SignUpPage.TermsConditions'))],
            [equals(7), always(t('SignUpPage.AddendumTitle'))],
        ])(currentIndex)
    }, [currentIndex, lang])

    const monthList = useMemo(() => {
        const monthList = [
            { code: '01', name: t('month.Jan') },
            { code: '02', name: t('month.Feb') },
            { code: '03', name: t('month.Mar') },
            { code: '04', name: t('month.Apr') },
            { code: '05', name: t('month.May') },
            { code: '06', name: t('month.Jun') },
            { code: '07', name: t('month.Jul') },
            { code: '08', name: t('month.Aug') },
            { code: '09', name: t('month.Sep') },
            { code: '10', name: t('month.Oct') },
            { code: '11', name: t('month.Nov') },
            { code: '12', name: t('month.Dec') },
        ]
        const renderList = monthList.slice(0, new Date().getMonth() + 1)

        return new Date().getFullYear() - yearOfBirthWatch === 15 ? renderList : monthList
    }, [lang, t, yearOfBirthWatch])

    const tips = [
        t('SignUpFormErrorMessage.Characters'),
        t('SignUpFormErrorMessage.Number'),
        t('SignUpFormErrorMessage.Uppercase'),
        t('SignUpFormErrorMessage.Lowercase'),
        t('SignUpFormErrorMessage.Symbol'),
    ]

    const onSubmit = (formData) => {
        checkProfileExistMutation.mutate({
            countryCode: formData.countryCode,
            emailId: formData.emailId,
            lang,
            mobileNo: formData.mobileNo,
        })
    }

    useEffect(() => {
        if (yearOfBirthWatch) {
            const minDate = new Date()
            const inputDate = new Date(yearOfBirthWatch, monthOfBirthWatch - 1)
            const fifteenYearsAgo = new Date(minDate.getFullYear() - 15, minDate.getMonth())
            if (inputDate.getTime() > fifteenYearsAgo.getTime()) {
                setValue('monthOfBirth', '')
            }
        }
    }, [yearOfBirthWatch])

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)} autoComplete='off' id='signUpForm'>
                <TextField
                    {...register('emailId', {
                        setValueAs: (v) => trim(v),
                    })}
                    fullWidth
                    label={t('SignUpPage.EmailAddressDisplay')}
                    variant='standard'
                    color='secondary'
                    placeholder={t('SignUpPage.emailHint')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={!!errors?.emailId}
                    helperText={errors?.emailId?.message ?? null}
                />

                <div className='flex space-x-3'>
                    <Controller
                        name='countryCode'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                select
                                label={t('SignUpPage.MobileDisplay')}
                                variant='standard'
                                color='secondary'
                                className='w-[100px]'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!error}
                                helperText={error ? error.message : null}
                                SelectProps={{
                                    MenuProps,
                                }}
                            >
                                {countryCodeList?.codeList?.map((option) => (
                                    <MenuItem key={option?.code} value={option?.code}>
                                        +{option?.code}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />

                    <TextField
                        {...register('mobileNo', {
                            setValueAs: (v) => trim(v),
                        })}
                        fullWidth
                        label=' '
                        variant='standard'
                        color='secondary'
                        placeholder={t('SignUpPage.YourNumberHint')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={!!errors?.mobileNo}
                        helperText={errors?.mobileNo?.message ?? null}
                    />
                </div>
                <TextField
                    {...register('password', {
                        setValueAs: (v) => trim(v),
                    })}
                    fullWidth
                    label={t('SignUpPage.PasswordDisplay')}
                    variant='standard'
                    type={showPassword ? 'text' : 'password'}
                    color='secondary'
                    placeholder={t('SignUpPage.PasswordHint')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={!!errors?.password}
                    helperText={errors?.password?.message ?? null}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end' className='w-[50px]'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={() => setShowPassword((show) => !show)}
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    {!showPassword ? <ImgIcon src={ViewOff} /> : <ImgIcon src={View} />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    focused={focus}
                />
                {focus ? (
                    <Box>
                        {tips.map((tip, index) => {
                            return (
                                <Box key={index} sx={{ fontSize: 14, fontWeight: 500 }} component='li'>
                                    {tip}
                                </Box>
                            )
                        })}
                    </Box>
                ) : null}
                <TextField
                    {...register('confirmPassword', {
                        setValueAs: (v) => trim(v),
                    })}
                    fullWidth
                    label={t('SignUpPage.ConfirmPasswordDisplay')}
                    variant='standard'
                    type={showConfirmPassword ? 'text' : 'password'}
                    color='secondary'
                    placeholder={t('SignUpPage.ConfirmPasswordHint')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={!!errors?.confirmPassword}
                    helperText={errors?.confirmPassword?.message ?? null}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end' className='w-[50px]'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={() => setShowConfirmPassword((show) => !show)}
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    {!showConfirmPassword ? <ImgIcon src={ViewOff} /> : <ImgIcon src={View} />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <div className={`flex space-x-3 ${!!iasmInfo ? 'items-end' : ''}`}>
                    <Controller
                        name='title'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                select
                                label={t('SignUpPage.TitleDisplay')}
                                variant='standard'
                                color='secondary'
                                className='w-[100px]'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!error}
                                helperText={error ? error.message : null}
                                SelectProps={{
                                    MenuProps,
                                    displayEmpty: true,
                                    renderValue: (selected) => {
                                        if (selected.length === 0) {
                                            return (
                                                <Typography className='text-secondary-midGray' variant='subtitle2'>
                                                    {t('Common.Select')}
                                                </Typography>
                                            )
                                        }
                                        return getNameByCode(selected, getTitle)
                                    },
                                }}
                            >
                                {getTitle?.map((option) => (
                                    <MenuItem key={option.code} value={option.code}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />

                    <TextField
                        {...register('lastname')}
                        fullWidth
                        label={t('SignUpPage.LastDisplay')}
                        variant='standard'
                        color='secondary'
                        placeholder={t('SignUpPage.LastNameHint')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={!!iasmInfo}
                        error={!!errors?.lastname}
                        helperText={errors?.lastname?.message ?? null}
                    />
                    {!!iasmInfo && <IasmIcon src={Iamsmart} />}
                </div>

                <Box className={`flex ${!!iasmInfo ? 'items-end' : ''}`}>
                    <TextField
                        {...register('firstname')}
                        fullWidth
                        label={t('SignUpPage.FirstNameDisplay')}
                        variant='standard'
                        color='secondary'
                        placeholder={t('SignUpPage.FirstNameHint')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={!!iasmInfo}
                        error={!!errors?.firstname}
                        helperText={errors?.firstname?.message ?? null}
                    />
                    {!!iasmInfo && <IasmIcon src={Iamsmart} />}
                </Box>

                <Box className={`flex ${!!iasmInfo ? 'items-end' : ''}`}>
                    <TextField
                        {...register('chineseName')}
                        className='w-full'
                        label={t('SignUpPage.ChineseNameDisplay')}
                        variant='standard'
                        color='secondary'
                        placeholder={t('SignUpPage.ChineseNameHint')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={!!iasmInfo?.chName}
                        error={!!errors?.chineseName}
                        helperText={errors?.chineseName?.message ?? null}
                    />
                    {!!iasmInfo?.chName && <IasmIcon src={Iamsmart} />}
                </Box>

                <TextField
                    {...register('nickname')}
                    fullWidth
                    label={t('SignUpPage.NicknameDisplay')}
                    variant='standard'
                    color='secondary'
                    placeholder={t('SignUpPage.NicknameHint')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={!!errors?.nickname}
                    helperText={errors?.nickname?.message ?? null}
                />

                <div className={`flex space-x-3 ${!!iasmInfo ? 'items-end' : ''}`}>
                    <Controller
                        name='monthOfBirth'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                select
                                label={t('SignUpPage.DateOfBirthDisplay')}
                                variant='standard'
                                color='secondary'
                                className='w-[200px]'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!error}
                                helperText={error ? error.message : null}
                                SelectProps={{
                                    MenuProps,
                                    displayEmpty: true,
                                    renderValue: (selected) => {
                                        if (selected.length === 0) {
                                            return (
                                                <Typography className='text-secondary-midGray' variant='subtitle2'>
                                                    {t('Common.Month')}
                                                </Typography>
                                            )
                                        }
                                        return getNameByCode(selected, monthList)
                                    },
                                }}
                                disabled={!!iasmInfo}
                            >
                                {monthList.map((v) => (
                                    <MenuItem key={v.code} value={v.code}>
                                        {v.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                    <Controller
                        name='yearOfBirth'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                select
                                label={' '}
                                variant='standard'
                                color='secondary'
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!error}
                                helperText={error ? error.message : null}
                                SelectProps={{
                                    MenuProps,
                                    displayEmpty: true,
                                    renderValue: (selected) => {
                                        if (selected.length === 0) {
                                            return (
                                                <Typography className='text-secondary-midGray' variant='subtitle2'>
                                                    {t('Common.Year')}
                                                </Typography>
                                            )
                                        }
                                        return selected
                                    },
                                }}
                                disabled={!!iasmInfo}
                            >
                                {yearList.map((v) => (
                                    <MenuItem key={v} value={v}>
                                        {v}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />

                    {!!iasmInfo && <IasmIcon src={Iamsmart} />}
                </div>

                <Controller
                    name='languagePreference'
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            select
                            label={t('SignUpPage.LanguagePreferencesDisplay')}
                            variant='standard'
                            color='secondary'
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            SelectProps={{
                                MenuProps,
                            }}
                        >
                            {languageList?.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                />

                <Controller
                    name='residentialArea'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            select
                            label={t('SignUpPage.ResidentialAreaDisplay')}
                            variant='standard'
                            color='secondary'
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!!error}
                            helperText={error ? error.message : null}
                            SelectProps={{
                                MenuProps,
                                displayEmpty: true,
                                renderValue: (selected) => {
                                    if (selected.length === 0) {
                                        return (
                                            <Typography className='text-secondary-midGray' variant='subtitle2'>
                                                {t('Common.Select')}
                                            </Typography>
                                        )
                                    }
                                    return getNameByIsoCode(selected, countryCodeList?.countryCodeList)
                                },
                            }}
                        >
                            {countryCodeList?.countryCodeList?.map((option) => (
                                <MenuItem key={option.isoCode} value={option.isoCode}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                />

                {/* 中国展示 */}
                {provinceList && (
                    <Controller
                        name='province'
                        control={control}
                        shouldUnregister={true}
                        defaultValue=''
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                select
                                label={t('SignUpPage.ProvinceDisplay')}
                                variant='standard'
                                color='secondary'
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!error}
                                helperText={error ? error.message : null}
                                SelectProps={{
                                    MenuProps,
                                    displayEmpty: true,
                                    renderValue: (selected) => {
                                        if (selected.length === 0) {
                                            return (
                                                <Typography className='text-secondary-midGray' variant='subtitle2'>
                                                    {t('Common.Select')}
                                                </Typography>
                                            )
                                        }
                                        return getNameByCode(selected, provinceList)
                                    },
                                }}
                            >
                                {provinceList?.map((option) => (
                                    <MenuItem key={option.code} value={option.code}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                )}

                {/* 香港展示 */}
                {districtList && (
                    <Controller
                        name='district'
                        control={control}
                        shouldUnregister={true}
                        defaultValue=''
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                select
                                label={t('SignUpPage.DistrictDisplay')}
                                variant='standard'
                                color='secondary'
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!error}
                                helperText={error ? error.message : null}
                                SelectProps={{
                                    MenuProps,
                                    displayEmpty: true,
                                    renderValue: (selected) => {
                                        if (selected.length === 0) {
                                            return (
                                                <Typography className='text-secondary-midGray' variant='subtitle2'>
                                                    {t('Common.Select')}
                                                </Typography>
                                            )
                                        }
                                        return getNameByCode(selected, districtList)
                                    },
                                }}
                            >
                                {districtList?.map((option) => {
                                    return option?.level === 0 ? (
                                        <ListSubheaderWrap key={option.code}>{option.name}</ListSubheaderWrap>
                                    ) : (
                                        <MenuItemWrap key={option.code} value={option.code}>
                                            {option.name}
                                        </MenuItemWrap>
                                    )
                                })}
                            </TextField>
                        )}
                    />
                )}

                <FormControl error={!!errors?.eNewIndicator || !!errors?.declareIndicatorAll} className='mt-[32px]'>
                    <FormGroup>
                        <FormControlLabel
                            className='items-start'
                            label={
                                <Typography variant='checkboxesText' className='block'>
                                    <Trans
                                        i18nKey='SignUpPage.ConfirmCheckbox'
                                        components={[
                                            <SpanWrap onClick={() => handleGetInfo(5)} />,
                                            <SpanWrap onClick={() => handleGetInfo(2)} />,
                                            <SpanWrap onClick={() => handleGetInfo(6)} />,
                                        ]}
                                    />
                                    {errors?.eNewIndicator?.message && (
                                        <FormHelperText className='mx-0 leading-4'>
                                            {errors?.eNewIndicator?.message}
                                        </FormHelperText>
                                    )}
                                </Typography>
                            }
                            control={<CheckboxWrap {...register('eNewIndicator')} />}
                        />
                        <FormControlLabel
                            className='mt-4 items-center'
                            label={<Typography variant='checkboxesText'>{t('SignUpPage.DeclareCheckbox')}</Typography>}
                            control={
                                <Controller
                                    name='declareIndicator'
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox {...field} className='py-0' checked={field.value} onChange={handleDeclareChange} />
                                    )}
                                />
                            }
                        />
                        {declareIndicatorWatch && (
                            <>
                                <div className='mt-4 w-full bg-[#f3f5f8] p-4'>
                                    <FormControlLabel
                                        className='items-start'
                                        label={
                                            <Typography variant='checkboxesText'>{t('SignUpPage.DeclareIndicatorAll')}</Typography>
                                        }
                                        control={
                                            <Controller
                                                name='declareIndicatorAll'
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        className='pt-0'
                                                        checked={field.value}
                                                        onChange={handleParentChange}
                                                    />
                                                )}
                                            />
                                        }
                                    />
                                    <div className='w-full h-[1px] bg-[#D4D6D9] my-3' />
                                    {children}
                                </div>
                                {submitStatus && errors?.declareIndicatorAll?.message && (
                                    <FormHelperText className='mx-0 leading-4'>
                                        {errors?.declareIndicatorAll?.message}
                                    </FormHelperText>
                                )}
                            </>
                        )}

                        <FormControlLabel
                            className='mt-4 items-start'
                            label={<Typography variant='checkboxesText'>{t('SignUpPage.AgreeCheckbox')}</Typography>}
                            control={<CheckboxWrap {...register('enewIndicator')} />}
                        />
                    </FormGroup>
                </FormControl>
            </Form>

            {/* <Dialog
                onClose={onClose}
                open={isOpen}
                scroll={'paper'}
            >
                <DialogTitleWrap>{popTitle}</DialogTitleWrap>
                <IconButtonWrap onClick={onClose}>
                    <Close />
                </IconButtonWrap>

                <DialogContent dividers>
                    <div dangerouslySetInnerHTML={{ __html: getContentType(currentIndex, infoData) }}></div>
                </DialogContent>
            </Dialog> */}
            <TipMotal
                onClose={onClose}
                isOpen={isOpen}
                modalComponent={
                    <Box>
                        <DialogTitleWrap>{popTitle}</DialogTitleWrap>
                        <CustomDialogContent>
                            <Box
                                sx={{ paddingTop: '16px' }}
                                className='ck-content'
                                dangerouslySetInnerHTML={{ __html: getContentType(currentIndex, infoData) }}
                            ></Box>
                        </CustomDialogContent>
                    </Box>
                }
            />
        </>
    )
}
